import DashboardGuard from "@/components/common/DashboardGuard/DashboardGuard.vue";

export const ROUTES_PATH = {
  CICD_APPLICATION_DETAIL: "/dashboard/cicd/app-detail/:applicationId",
  CICD: "/dashboard/cicd",
  KUBERNETES: "/dashboard/kubernetes",
  DORA: "/dashboard/dora",
  CYCLE_TIME: "/dashboard/cycletime",
  DORA_CYCLE_TIME: "/dashboard/dora-cycle-time",
  DEVOPS_METRIC_CONFIG: "/dashboard/devops-metric-config",
  CODE_QUALITY: "/dashboard/code-quality",
  CODE_QUALITY_2: "/dashboard/code-quality-2",
};

export default [
  {
    path: "",
    component: DashboardGuard,
    children: [
      {
        path: ROUTES_PATH.CICD,
        name: "CICDStatus",
        component: () => import("../pages/dashboard/CICDStatus/CICDStatus.vue"),
        meta: {
          title: "CI/CD 대시보드",
          // icon: "mdi-domino-mask",
          isMenuItem: true,
          breadCrumb: [{ text: "CI/CD 대시보드" }],
        },
      },
      {
        path: ROUTES_PATH.CICD_APPLICATION_DETAIL,
        name: "CICDApplicationStatus",
        component: () =>
          import("../pages/dashboard/CICDStatus/ApplicationDetail.vue"),
        meta: {
          title: "CI/CD Application",
          isMenuItem: true,
          breadCrumb: [{ text: "CI/CD Application" }],
        },
      },
      {
        path: ROUTES_PATH.KUBERNETES,
        name: "RunningKubernetes",
        component: () =>
          import("../pages/dashboard/RunningKubernetes/RunningKubernetes.vue"),
        meta: {
          title: "Running Kubernetes",
          // icon: "mdi-domino-mask",
          isMenuItem: true,
          breadCrumb: [{ text: "Running Kubernetes" }],
        },
      },
    ],
  },
  {
    path: ROUTES_PATH.DORA,
    name: "DORA",
    component: () => import("../pages/dashboard/Dora/Dora.vue"),
    meta: {
      title: "DORA",
      isMenuItem: true,
      breadCrumb: [{ text: "DORA" }],
    },
  },
  {
    path: ROUTES_PATH.CYCLE_TIME,
    name: "CycleTime",
    component: () => import("../pages/dashboard/CycleTime/index.vue"),
    meta: {
      title: "Cycle Time",
      isMenuItem: true,
      breadCrumb: [{ text: "Cycle Time" }],
    },
  },
  {
    path: ROUTES_PATH.DORA_CYCLE_TIME,
    name: "Dora & Cycle Time",
    component: () => import("../pages/dashboard/DoraCycleTime/index.vue"),
    meta: {
      title: "Dora & Cycle Time",
      isMenuItem: true,
      breadCrumb: [{ text: "Dora & Cycle Time" }],
    },
  },
  {
    path: ROUTES_PATH.DEVOPS_METRIC_CONFIG,
    name: "DEVOPS METRIC CONFIG",
    component: () =>
      import("../pages/dashboard/DevopsMetricConfig/DevopsMetricConfig.vue"),
    meta: {
      title: "Devops Metric Config",
      isMenuItem: true,
      breadCrumb: [{ text: "Devops Metric Config" }],
    },
  },
  {
    path: ROUTES_PATH.CODE_QUALITY,
    name: "CodeQuality",
    component: () => import("../pages/dashboard/CodeQuality/CodeQuality.vue"),
    meta: {
      title: "Code Quality",
      isMenuItem: true,
      breadCrumb: [{ text: "Code Quality" }],
    },
  },
  {
    path: ROUTES_PATH.CODE_QUALITY_2,
    name: "CodeQuality2",
    component: () => import("../pages/dashboard/CodeQuality2/CodeQuality.vue"),
    meta: {
      title: "Code Quality 2",
      isMenuItem: true,
      breadCrumb: [{ text: "Code Quality 2" }],
    },
  },
];
